@import url('https://use.typekit.net/uyg3umz.css');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    'Segoe UI, ' Roboto ', sans-serif;', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

.app-btn,
.redirect-btn,
.link-btn {
  cursor: pointer;
}

.app-btn:hover,
.redirect-btn:hover,
.link-btn:hover {
  opacity: 0.6;
}

.fb-button {
  padding: 10px 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  transition: all 0.5;
  background-color: #3b589e;
  color: #ffffff;
  font-size: 36px;
  cursor: pointer;
  border: none;
  justify-content: center;
}
.ant-spin-container {
  /* background: #e2e2e2; */
}
.ant-layout-content {
  width: 100%;
  background-color: #fff;
}

.link:hover {
  color: #d42a1c;
}

.redirect-btn:hover {
  cursor: pointer;
  color: #d42a1c !important;
}

span,
p,
b,
i,
a,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans JP';
  margin-bottom: 0;
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 200;
  src: url('fonts/NotoSansJP-Light.otf');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 300;
  src: url('fonts/NotoSansJP-Light.otf');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 400;
  src: url('fonts/NotoSansJP-Regular.otf');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 500;
  src: url('fonts/NotoSansJP-Medium.otf');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 600;
  src: url('fonts/NotoSansJP-Bold.otf');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 700;
  src: url('fonts/NotoSansJP-Bold.otf');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 800;
  src: url('fonts/NotoSansJP-Black.otf');
}

@font-face {
  font-family: 'Noto Sans JP';
  font-weight: 900;
  src: url('fonts/NotoSansJP-Black.otf');
}

@font-face {
  font-family: 'RyuminPro';
  src: url('fonts/RyuminPro-Bold.otf');
}

@font-face {
  font-family: 'ShinGoPr6';
  src: url('fonts/ShinGoPr6-DeBold.otf');
}
